import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    // width: 300,
    margin: "0px 10px 0px 10px",
  },
});

function ComingSoon() {
  return (
    <div className="App">
      <header className="App-header">
        Coming soon 🥳
      </header>
    </div>
  );
}

function PageNotFound() {
  return (
    <div className="App">
      <header className="App-header">
        😢 Uh oh. We couldn't find that page.
      </header>
    </div>
  );
}

function Privacy() {
  return (
    <div className="App">
      <header className="App-header">
        <ul>
          <li>Privacy info</li>
          <li>More privacy info</li>
        </ul>
      </header>
    </div>
  );
}

export {ComingSoon, PageNotFound, Privacy}
